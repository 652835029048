











import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default defineComponent({
  components: { CBadge },
  props: {
    rounded: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { faCheckCircle }
  }
})
